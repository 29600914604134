import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Memorial Day Murph will be at CrossFit Louisville East today at
10:00am.  All are invited and the wod can be scaled as needed for
anyone.  All other classes and open gym are cancelled today at both
locations so come out to East today at 10:00am and join in the
fun/pain!`}</em></strong></p>
    <p><strong parentName="p">{`“Murph”`}</strong></p>
    <p>{`1 Mile Run`}</p>
    <p>{`100-Pullups`}</p>
    <p>{`200-Pushups`}</p>
    <p>{`300-Squats`}</p>
    <p>{`1 Mile Run`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`If you have body armor or a weight vest wear it.`}</em></p>
    <p><em parentName="p">{`*`}{`Partition the pullups, pushups & squats as needed.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      